.archk-upload {

//     <div className="archk-upload">
//     <div className="archk-upload-blackout" />
//     <div className="archk-upload-main" >
//         <div className="archk-upload-section">Upload Document</div>
//     </div>
    
// </div>
    .archk-upload-blackout {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1055;
        background: rgba(0,0,0,.4)
    }
    .archk-upload-main {
        position: fixed;
        background: $white;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1060;
        width: 900px;
        max-width: 90%;
        overflow: auto;
    }

    .archk-upload-section {
        padding: $spacer;
    }

}