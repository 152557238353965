.card {
    // box-shadow: $box-shadow-sm;
    // margin-bottom: 0;
    // border-radius: 0;
    // position: relative;

    // &:before
    // {
    //   z-index: -1;
    //   position: absolute;
    //   content: "";
    //   bottom: 15px;
    //   left: 10px;
    //   width: 50%;
    //   top: 80%;
    //   max-width:300px;
    //   background: #777;
    //   -webkit-box-shadow: 0 15px 10px #777;
    //   -moz-box-shadow: 0 15px 10px #777;
    //   box-shadow: 0 15px 10px #777;
    //   -webkit-transform: rotate(-3deg);
    //   -moz-transform: rotate(-3deg);
    //   -o-transform: rotate(-3deg);
    //   -ms-transform: rotate(-3deg);
    //   transform: rotate(-3deg);
    // }

    // box-shadow: $box-shadow-sm;
    // box-shadow: none;
    border: solid 1px $gray-300;

    // background-color: $gray-200;

    // .card-body {
    //     background-color: $gray-100;
    // }
    
    // .card-footer {
    //     background-color: $gray-100;
    // }
    // .card-header {
    //     background-color: $gray-100;
    // }

    // -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    // box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

    // box-shadow: 
    //     rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px;

    // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    // border-left: none;
    // border-right: none;

    box-shadow: none;

    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    &.card-aggregation {
        a {
            display: block;
            &:hover {
                background-color: $gray-200;
            }
        }

        .card-body {
            padding-left: 0;
            padding-right: 0;
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }

        .col-6, .col-lg-2 {
            padding-left: 0;
            padding-right: 0;
        }

    }

    .card-title {
        font-weight: bold;
    }

}

.card.card-no-shadow {
    box-shadow: none;
}