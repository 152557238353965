.rna-wrapper {

    z-index: 999999999;
    .alert {
        width: calc(100%);
    }

   



}

$spacing: 90px;

@for $i from 0 through 30 {
    .rna-wrapper:nth-child(#{$i}) .col-11.col-sm-4 {

        // -2 is to correct for a weird offset that is happing
        top: #{$spacing * (($i - 4)) + 20} !important;
    }
}