.archk-tutorial {

    .blackout {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 99999;
        background-color: rgba(0,0,0,.8);
    }

    // .archk-tutorial-whiteout {
    //     position: absolute;
    //     z-index: 999999;
    //     background: white;
    // }

    .archk-tutorial-modal {
        position: absolute;
        z-index: 999998;
        background: white;
        -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
        box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);

        .arrow {
            position: absolute;
            top: -20px;
            left: calc(50% - 5px);
            z-index: 9999999;
        }

        .title {
            padding: $modal-inner-padding;
            p {
                font-weight: bold;
            }
        }

        .body {
            padding: $modal-inner-padding;
        }

        .controls {
            padding: $modal-inner-padding;
        }
    }
   

}

.archk-tutorial-highlight {
    position: relative;
    z-index: 999999;
    background: $white;
    -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    outline: dashed 4px $info;
}

th .archk-tutorial-highlight {
    background: inherit;
}
.btn.archk-tutorial-highlight {
    background: inherit;
}