.noScroll {
    /* ...or body.dialogShowing */
    overflow: hidden !important;
}

.cursor-pointer {
    cursor: pointer;
}

.progress-gradient.progress {

    &.finished {
        .progress-bar {
            background: $green !important
        }
    }

    .progress-bar {
        background: linear-gradient(to right, $red 5%, $orange 20%, $green 60%) !important;
    }

}

.background.full-background {
    overflow: scroll;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    background: $body-bg;
    padding: $spacer * 3;
}

.list-style-none {
    list-style: none;
}

ul.hover-click {
    li {
        &:hover {
            background: $gray-200;
            cursor: pointer;
        }
    }
}

.dz-message {
    z-index: 30;
}

.border-warning {
    border: solid 1px $orange;
}
.border-danger {
    border: solid 1px $red;
}
.border-success {
    border: solid 1px $green;
}
.border-info {
    border: solid 1px $teal;
}

.border-radius-50 {
    border-radius: 50px;
}

.container-half {
    width: 500px;
}

.card .archk-api-error, .modal .archk-api-error {
    border-radius: 0 !important;

    background: $orange !important;
    color: white;

    .text-info,.text-warning,.text-danger, .text-dark {
        color: $white !important;
    }
}