.layout-onboarding {

    .flow-icon {
        position: absolute;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 10px;
        top: -40px;
        right: 0px;
        width: 80px;
        height: 80px;
        z-index: 1;
    }

    @include media-breakpoint-down(sm) {
        .flow-icon {
            position: absolute;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 5px;
            top: -20px;
            right: 0px;
            width: 60px;
            height: 60px;
            z-index: 1;
        }

        .flow-icon-header {
            max-width: 80%;
        }
        
    }

}