.archk-alerts {

    


    .archk-alert {
        position: fixed;
        top: 53px;
        right: 10px;
        width: 400px;
        z-index: 99999999999;
        background: white;
        border: solid 1px $gray-300;
        padding: 8px 12px;
        border-radius: .25rem;
        // -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        // box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        font-size: 16px;

        &:before {
            content: '';
            position: absolute;
            width: 0;
            height: calc(100% - 44px);
            border-left: 1px solid;
            border-right: 2px solid;
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
            left: 0;
            top: 50%;
            transform: translate(0,-50%);
              height: 20px;
        }

        i {
            display: inline-block;
            width: 24px;
        }

        animation: archkAlertsSideIn 3s;

        @keyframes archkAlertsSideIn {
            0%   { right: -500px }
            20%  { right: 10px;  }
            100% { right: 10px;  }
        }


        &.archk-alert-success {
            border: solid 1px $green;
            background: lighten($green, 40);
            color: $green;    
        }
        &.archk-alert-info {
            border: solid 1px $teal;
            background: lighten($teal, 40);
            color: $teal;    
        }
        &.archk-alert-warning {
            border: solid 1px $orange;
            background: lighten($orange, 30);
            color: $orange;    
        }
        &.archk-alert-danger {
            border: solid 1px $red;
            background: lighten($red, 30);
            color: $red;    
        }

        .title {
            font-weight: bold;
            text-transform: uppercase;
        }
    
        &:nth-child(2) {
            top: 103px;
        }
        &:nth-child(3) {
            top: 153px;
        }
        &:nth-child(4) {
            top: 203px;
        }
        &:nth-child(5) {
            top: 253px;
        }
        &:nth-child(6) {
            top: 303px;
        }
        &:nth-child(7) {
            top: 353px;
        }
        &:nth-child(8) {
            top: 403px;
        }
        &:nth-child(9) {
            top: 453px;
        }
        &:nth-child(10) {
            top: 503px;
        }

    }

}