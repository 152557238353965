.vertical-timeline-element--work {

    i {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 30%;
    }

    .vertical-timeline-element-icon {
        background-color: $orange;
        color: white;
    }

    .vertical-timeline-element-date {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    &.type-user {
        .vertical-timeline-element-icon {
            background-color: $blue;
            color: white;
        }
    }

    &.type-system {
        .vertical-timeline-element-icon {
            background-color: $primary;
            color: white;
        }
    }

    &.type-action {
        .vertical-timeline-element-icon {
            background-color: $red;
            color: white;
        }
    }

    &.type-success {
        .vertical-timeline-element-icon {
            background-color: $green;
            color: white;
        }
    }

    &.type-info {
        .vertical-timeline-element-icon {
            background-color: $purple;
            color: white;
        }
    }

}