.archk-object-search-input-portal.results {

    list-style: none;
    padding-left: 0;
    background: white;;
    -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    border: solid 2px $gray-500;
    position: absolute;
    z-index: 999999;
    left: 0;
    right: 0;
    top: 56px;
    max-height: 200px;
    overflow: auto;
    bottom: 10px;

    li {
        background: $white;
        border-bottom: solid 1px $gray-700;;
        padding: $spacer * .7 $spacer;

        &:hover {
            background: $gray-200;;
            cursor: pointer;
        }

        &.title {
            background: white;;
            font-weight: bold;

        }

    }

}

.modal {

    .object-search-input {

        .results {
            position: static ;
        }

    }

}
