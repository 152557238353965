.editor-content {
    .archk-workflows-header.fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 500;
    }

}

.archk-workflow {

    
    .archk-workflow-sidebar {
        position: fixed;
        left: 0;
        top: 53px;
        bottom: 0;
        width: 250px;
        z-index: 550;
        background-color: white;
        border-right: solid 1px $gray-300;

        .archk-workflow-sidebar-header {
            
        }
        .archk-workflow-sidebar-body {
            overflow: auto;
            max-height: calc(100vh - 97px);
            border-top: solid 1px $gray-200;
        }

        .archk-workflow-sidebar-spacer {
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
            padding-top: $spacer;
            padding-bottom: $spacer;
        }
        
        .archk-workflow-sidebar-spacer-vertical {
            padding-top: $spacer;
            padding-bottom: $spacer;
        }

        .archk-workflow-sidebar-step {

            &:hover {
                background: $info !important;

                p, i {
                    color: $white;
                }
            }

            .archk-workflow-sidebar-step-icons {
                i {
                    margin-right: $spacer / 2;
                    font-size: $font-size-sm;
                    display: inline-block;
                }
            }

        }

       

        .archk-workflow-sidebar-edit-blackout {
            position: fixed;
            right: 0;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 550;
            background-color: rgba(0,0,0,.5);
        }
        .archk-workflow-sidebar-edit-modal {
            position: fixed;
            right: 0;
            top: 0;
            bottom: 0;
            width: 800px;
            z-index: 560;
            background-color: white;
            overflow: auto;
            border-left: solid 1px $gray-300;
        }
    }


    .archk-workflow-save {
        position: fixed;
        top: 12px;
        right: 8px;
        z-index: 550;
    }

    .archk-workflow-main {
        margin-left: 230px;
        margin-top: 75px;

        .archk-workflow-main-step-name-first {
            position: relative;
            top: -16px;
        } 

        ul.archk-workflow-main-ul-first {
            &:before {
                display: none;
            }
        }

        ul {

            position: relative;
            padding-left: 10px;
            margin-top: 10px;

            &:before {
                content: "";
                width: 46px;
                border-left: 1px dotted #8898aa;
                position: absolute;
                left: -8px;
                height: calc(100% - 10px);
                top: -19px;
            }


            li {
                list-style: none;
                list-style: none;
                padding-left: 20px;
                padding-bottom: 2px;
                position: relative;
                top: -15px;
                width: auto;

                &:before {
                    content: "";
                    width: 28px;
                    height: 0;
                    border-top: 1px dotted #8898aa;
                    position: absolute;
                    left: -17px;
                    top: 10px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 12px;
                    top: 5px;
                    width: 0;
                    height: 0;
                    border-top: 5px dotted transparent;
                    border-bottom: 5px dotted transparent;
                    border-left: 5px solid black;
                    clear: both;
                }

                &.archk-can-branch:after {
                    border-left: 5px solid $info;
                }
                &.archk-can-branch:before {
                    border-top: 1px dotted $info;
                }

                &.archk-workflow-main-insert-step {
                    position: relative;
                    top: -10px;
                    margin-bottom: -12px;
                }

                .btn {
                    padding: .25rem .5rem !important;
                    height: auto;
                    font-size: 10px;
                    min-width: 0 !important;
                    line-height: 10px;
                    margin-top: 8px;
                    position: relative;
                    top: -8px;
                    margin-bottom: -0px;
                }

                .archk-workflow-main-step-name {
                    position: relative;
                    top: 4px;
                }

            }

            .archk-workflow-main-step-span {
                &:hover {
                    color: $green;
                    font-weight: bold;
                }
            }

        }
    }

}