.archk-analysis {

    &.download {

        .card {
            border: solid 1px $gray-200;
        }

        .white-out {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 99999;
        }

        .col {
            min-height: 0;
        }

        .col-auto {
            width: 100% !important;
            display: block;
            border-radius: none;
            position: static;
            border-left: none;
            padding-left: $spacer;
            padding-right: $spacer;
            margin-left: 0;

            .wrapper-left-section {
                &:last-child {
                    border-bottom: solid 1px $gray-200;
                }
    
            }

        }

        .chart-wrapper {
            margin-left: 0;
            border-left: 0;
            border-left: none !important;
        }
       
    }

    .chart-wrapper {
        // position: sticky;
        // top: 0;
        // left: 0;
        // bottom: 0;
        // right: 0;
        margin-left: 200px;
    }
    .chart-container {
        position: relative;
        width: 99%;
        overflow: hidden;
    }

    .dash-header-open {
        .card-body {
            padding: $card-spacer-y $card-spacer-x;
        }
    }

    .card-body {
        padding: 0;
        // overflow: hidden;
    }

    .no-data {
        margin: $card-spacer-y $card-spacer-x;
    }

    .col {
        padding-right: $card-spacer-x;
        padding-left: $card-spacer-x;
        min-height: 400px;
    }

    .row {
        position: relative;
    }

    .col-auto {

        padding-right: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 206px;
        // overflow: hidden;
        margin-left:  $card-columns-margin;
        padding-left: 0;
        z-index: 2;

        .wrapper-left-section {
            padding: $card-spacer-y $card-spacer-x;
            padding-left: $card-spacer-x;
            border-bottom: solid 1px $gray-200;
            &:last-child {
                border-bottom: none
            }
            &.can-activate {
                &:hover {
                    cursor: pointer;
                    background-color: $gray-100;
                }
            }

            i {
                display: inline-block;
                width: 20px;
            }
        }

    }

    
    
}

.modal-pay-stub-analyze {

    .modal-body {
        padding: 0;
    }

    .col-auto {
        padding: 0;
    }

    .col {
        padding: 0;
    }

    .section {
        padding: $modal-inner-padding;
    }

    .row {
        margin: 0;
    }

    ul {
        // margin-bottom: $spacer;
    }
    li {

        padding: 3px $spacer;
        border: solid 1px $white;
        cursor: pointer;
        border: solid 1px $gray-200;

        transition: all .2s;

        &:hover {

            border: solid 1px $orange;
            color: $orange;
            // border-radius: $border-radius;
        
        }

        &.active {
            border: solid 1px $green;
            color: $green;
            // border-radius: $border-radius;
    
        }

    }

    .react-datepicker-wrapper input {
        padding-left: $spacer;
    }
}


@include media-breakpoint-down(md) {
    .archk-analysis {

        .dash-header-open {
            .btn {
                width: 100%;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }

        .card-title {
            .btn {
                width: 100%;
                margin-left: 0 !important;
                margin-right: 0 !important;
                margin-top: 8px;
            }
        }
        canvas {
            margin-top: $spacer;
        }

        .card-body {
            .row {
                display: block;
            }
        }

        .card-header {
            .text-right {
                text-align: left !important;
            }
        }

        .chart-wrapper {
            margin-left: 0;
        }

        .col {
            display: block;
            min-height: 0;
            margin-left: 0;
            padding-left: $spacer;
        }
        .col-auto {
            display: block;
            min-height: 0;
            position: static;
            width: 100%;
            padding-right: $spacer * 2;

        }
    }   
}