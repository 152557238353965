.archk-background-processes {

    $headerHeight: 56px;

    .sidebar-left {
        position: fixed;
        left: 0;
        top: $headerHeight;
        bottom: 0;
        background: $white;
        z-index: 3;
        width: 250px;
        overflow: auto;
        padding: 0 20px;

        .sidebar-item {
            padding: 10px 0px;

            &.can-drag {
                border: solid 1px $gray-300;
                background: $gray-100;
                padding: 10px 16px;
                border-radius: 6px;
                margin-bottom: 10px;
                margin-top: 12px;
                font-weight: bold;

            }

            i {
                text-align: center;
                width: 28px;
                font-size: 18px;
                position: relative;
                top: 1px;
            }

        }

    }
    .sidebar-right {
        position: fixed;
        right: 0;
        top: $headerHeight;
        bottom: 0;
        background: $white;
        z-index: 3;
        width: 350px;
        overflow: auto;

        .sidebar-section {
            padding: 10px 20px;
        }


    }

    .main {
        position: fixed;
        right: 0;
        left: 250px;
        top: $headerHeight;

        bottom: 0;
        background: $white;
        z-index: -2;
        width: 100%;
        height: calc(100vh - $headerHeight);
        background: rgba(0,0,0,.6);
        // padding-left: 20px;
        // padding-right: 20px;
        // padding-top: 20px;
    }

    .react-flow__node {
        padding: 0;
        border-radius: 6px;
        min-width: 250px;
        max-width: 350px;
        font-size: 12px;
        color: #222;
        text-align: center;
        border: solid 2px $gray-600;
        background-color: white;
        text-align: left;

        &.selected {
            border: solid 2px $success;
            outline: solid 2px $success;

            -webkit-box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
            box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
        }

        .node-header {
            background: $gray-200;
            padding: 6px 8px;

            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
        }
        .node-footer {
            background: $gray-200;
            padding: 6px 8px;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
        }
        
        .node-body {
            padding-left: 6px;
            padding-right: 6px;
            padding-top: 8px;
            padding-bottom: 8px;
            
            .node-item {

                border: solid 1px $gray-400;
                border-radius: 6px;
                padding: 4px 6px;

                &:not(:first-child) {
                    margin-top: 6px;
                }

            }

        }

        .react-flow__handle {
            
            &.connectionindicator {
                background: $info;
                padding: 4px
            }

        }

    }

}