
.archk-monitor-iframe-wrapper {

    .archk-monitor-iframe {
        position: fixed;
        z-index: 10000;
        overflow: hidden;
    }

    iframe{
       
        width: 100%;
        height: 100%;

        border: none;
        outline: none;
    }

    .archk-monitor-iframe-total-links-1 {
        .archk-monitor-iframe-1 {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    .archk-monitor-iframe-total-links-2 {
        .archk-monitor-iframe-1 {
            top: 0;
            bottom: 0;
            left: 0;
            right: 50%;
            border: inset 4px $gray-400;
            border-radius: 8px;
            margin: 6px;
        }
        .archk-monitor-iframe-2 {
            top: 0;
            bottom: 0;
            left: 50%;
            right: 0;
            border: inset 4px $gray-400;
            border-radius: 8px;
            margin: 6px;
        }
    }
    
    .archk-monitor-iframe-total-links-3 {
        .archk-monitor-iframe-1 {
            top: 0;
            bottom: 50%;
            left: 0;
            right: 0;
            border: inset 4px $gray-400;
            border-radius: 8px;
            margin: 6px;
        }
        .archk-monitor-iframe-2 {
            top: 50%;
            bottom: 0;
            left: 0;
            right: 50%;
            border: inset 4px $gray-400;
            border-radius: 8px;
            margin: 6px;
        }
        .archk-monitor-iframe-3 {
            top: 50%;
            bottom: 0;
            left: 50%;
            right: 0;
            border: inset 4px $gray-400;
            border-radius: 8px;
            margin: 6px;
        }
    }
   
    .archk-monitor-iframe-total-links-4 {
        .archk-monitor-iframe-1 {
            top: 0;
            bottom: 50%;
            left: 0;
            right: 50%;
            border: inset 4px $gray-400;
            border-radius: 8px;
            margin: 6px;
        }
        .archk-monitor-iframe-2 {
            top: 0;
            bottom: 50%;
            left: 50%;
            right: 0;
            border: inset 4px $gray-400;
            border-radius: 8px;
            margin: 6px;
        }
        .archk-monitor-iframe-3 {
            top: 50%;
            bottom: 0;
            left: 0;
            right: 50%;
            border: inset 4px $gray-400;
            border-radius: 8px;
            margin: 6px;
        }
        .archk-monitor-iframe-4 {
            top: 50%;
            bottom: 0;
            left: 50%;
            right: 0;
            border: inset 4px $gray-400;
            border-radius: 8px;
            margin: 6px;
        }
    }
}
