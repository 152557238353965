.layout-auth {
    
    .mfa-option {
        transition: .2s all;
        &:hover {
            background: $gray-200;
            cursor: pointer;
        }
    }

    .mfa-auth-code {
        input {
            border-radius: 0;
            height: 60px;
            font-size: 30px;
            padding: 0;
            text-align: center;
            font-family: monospace;
        }

        .row {
            margin: 0;
        }

        .col-2 {
            &:nth-child(1) {
                padding-right: 3px;
                padding-left: 3px;
            }
            &:nth-child(2) {
                padding-left: 3px;
                padding-right: 3px;
            }
            &:nth-child(3) {
                padding-left: 3px;
            }

            &:nth-child(4) {
                padding-right: 3px;
            }
            &:nth-child(5) {
                padding-left: 3px;
                padding-right: 3px;
            }
            &:nth-child(6) {
                padding-right: 3px;
                padding-left: 3px;
            }
        }

        
    }

}