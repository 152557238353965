
.ql-toolbar + .ql-container .ql-editor {

    max-height: 400px;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0;
}

.ql-container.ql-snow {
    border: none;
}

.ql-snow .ql-editor {

    padding: $spacer * 2;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: $headings-margin-bottom;
    }

    h1 {
        color: $black !important;
        font-size: 16px !important;
        font-family: 'Roboto Slab', serif !important;
    }
    h2 {
        color: $black !important;
        font-size: 16px !important;
        font-family: 'Roboto Slab', serif !important;
    }
    h3 {
        color: $black !important;
        font-size: 16px !important;
        font-family: 'Roboto Slab', serif !important;
    }
    h4 {
        color: $black !important;
        font-size: 16px !important;
        font-family: 'Roboto Slab', serif !important;
    }
    h5 {
        color: $black !important;
        font-size: 16px !important;
        font-family: 'Roboto Slab', serif !important;
    }
    h6 {
        color: $black !important;
        font-size: 16px !important;
        font-family: 'Roboto Slab', serif !important;
    }

    p {
        font-size: 12px !important;
        margin-bottom: $spacer;
        color: $black !important;
        font-family: 'Roboto Slab', serif !important
    }

    li {
        font-size: 12px !important;
        margin-bottom: $spacer;
        color: $black !important;
        font-family: 'Roboto Slab', serif !important
    }

    strong {
        font-weight: bold;
    }

}

.ql-picker-label {

    svg {
        width: 25px
    }

}

.ql-formats {
    padding-right: 0;
}

#case_feed {

    .ql-editor {
        border-bottom: solid 1px $gray-200 !important;
        padding: 5px 10px;
        font-size: $font-size-sm;
        color: $body-color;

        p {
            font-family: $font-family-sans-serif !important;

        }
    }

}

.ql-editor {
    border-bottom: none !important;
    box-shadow: none;
}

.ql-editor:focus {
    box-shadow: none
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: $green !important;;
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: $green;
}

