.footer-wrapper {

    $footer-padding: 10px;

    footer {
        padding-bottom: $footer-padding;
        padding-top: $footer-padding;
        background-color: transparent;

    }

}


// .footer .nav .nav-item .nav-link, .footer .footer-link {
//     color: white !important;
// }