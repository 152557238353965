
.z-depth-0 {
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.z-depth-1 {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.z-depth-2 {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}
.z-depth-3 {
    -webkit-box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}
.z-depth-4 {
    -webkit-box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
}
.z-depth-5 {
    -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
}

.raise-1 {
    z-index: 1;
}

.raise-2 {
    z-index: 2;
}

.raise-3 {
    z-index: 3;
}
 
.raise-4 {
    z-index: 4;
}

.lower-1 {
    z-index: -1;
}

.lower-1 {
    z-index: -2;
}

.lower-3 {
    z-index: -3;
}

.lower-4 {
    z-index: -4;
}

.employment-circle {

    .logo-circle {
        border: solid 3px white;


        color: $white;
        margin: 2px;
        border-radius: 100%;
        width: 40px;
        text-align: center;
        height: 40px;
        -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

        img {
            border-radius: 100%;
        }
    }

    .employment-circle-inner {
        border: solid 3px white;
        background-color: $green;
        padding-top: 5px;
        color: $white;
        margin: 2px;
        border-radius: 100%;
        width: 40px;
        text-align: center;
        height: 40px;
        -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

        &.employment-circle-inner-green {
            background-color: rgb(77, 179, 106);
        }
        &.employment-circle-inner-yellow {
            background-color: rgb(235, 174, 17);
        }
        &.employment-circle-inner-orange {
            background-color: rgb(235, 121, 17);
        }
        &.employment-circle-inner-pink {
            background-color: rgb(224, 114, 114);
        }
        &.employment-circle-inner-blue {
            background-color: rgb(88, 155, 255);
        }
        &.employment-circle-inner-purple {
            background-color: rgb(131, 88, 255);
        }

    }

}

#archk-search-modal {
    .card {
        margin-bottom: 0;
        box-shadow: none !important;

        .card-header {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
        .card-footer {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
}

@include media-breakpoint-down(md) {
    .archk-dashboard-box {
        border: solid 1px $gray-100 !important;
    }
}

@include media-breakpoint-up(lg) {

    .vertical-timeline {
        padding: 0;
        margin-bottom: 30px;
    }

    .vertical-timeline-element {
        margin-top: 0px;
        margin-bottom: 15px;

        p {
            margin-top: 0px;
        }

        .vertical-timeline-element-date {
            padding-top: 5px;
        }
    }

    .vertical-timeline-element-content {
        padding: .5rem .75rem;
    }
}

.archk-connect-iframe {
  
    position: fixed;
    top: 160px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;

    border-top: solid 2px $gray-400;
    border-bottom: solid 2px $gray-400;

    iframe{
       
        width: 100%;
        height: 100%;

        border: none;
        outline: none;
    }
}

.avatar-image {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: solid 1px rgba(255,255,255,.3);
}

.custom-shadow-1 {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.custom-shadow-2 {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.custom-shadow-3 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.custom-shadow-4 {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    -webkit-box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}