
.hover-popup {

    position: relative;
    cursor: pointer;

    &:hover {

        .pop {
            display: inline-block;
            position: absolute;
            top: -$spacer * 4;
            left: $spacer * 2;
            background: $body-bg;
            border: solid 1px #ddd;
            border-radius: $border-radius;
            padding: $spacer;
            white-space: pre;
            word-break: break-all;
            box-shadow: $box-shadow-sm;
            z-index: 9999999999;
        }
    
    }
   

    .pop {
        display: none;
    }

}