.archk-query {
    height: calc(100% - 200px);

    .archk-range-selection {
        position: fixed;
        top: 40px;
        left: 200px;
        right: 0px;
        z-index: 2;

    }

    .archk-query-results {
        position: fixed;
        left: 200px;
        bottom: 0;
        right: 0;
        top: 102px;
        overflow: auto;
        // background: $gray-100;
    }

    .archk-query-sidebar {
        height: calc(100vh - 40px);

        position: fixed;
        left: 0;
        top: 40px;
        right: 0;

        width: 200px;

        overflow: auto;

        .archk-query-section  {
            padding-top: $spacer;
            padding-left: $spacer;
            padding-right: $spacer;
            border-bottom: solid 1px $gray-200;
        }

    }

    .archk-query-main {
        // margin-left: 200px;
        // margin-top: 105px;
        // height: 100vh;

        background-color: $gray-200;

        .react-bootstrap-table-pagination {
            border-bottom: solid 1px $gray-200;
            // padding-top: $spacer * 2;
            // margin-top: -8px;
        }

        .dataTables_length {
            margin-top: 3px;
        }

        table  {
            thead {

            

                th {

                    // &:after {
                    //     background-color: #fff;
                    //     height: 1px;
                    //     content: "";
                    //     position: absolute;
                    //     bottom: 0px;
                    //     left: 0;
                    //     right: 0;
                    //     box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

                    // }
                    box-shadow: inset 0 0px 0 $gray-400, inset 0 -1px 0 $gray-500;

                    &:first-child {
                        position: sticky;
                        left: 0;
                        z-index: 3;
                        box-shadow: inset 0 0px 0 $gray-400, inset 0 -1px 0 $gray-500;

                      }

                    // border-bottom: solid 2px $gray-400;
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    width: 25vw;

                }
            }



            tbody {
                td {
                    &:first-child {
                        position: sticky;
                        left: 0;
                        z-index: 2;
                        background: white;
                      }

                }

                tr:nth-child(odd) {
                        background: #f6f9fc;

                }
            }
        }
        // table.table {
        //     .first-cell {
        //         padding-left: 15px;
        //     }
        //     .last-cell {
        //         padding-right: 15px;
        //     }
        // }

    }

}