.form-control:disabled {
    background-color: $gray-300 !important;
}

select {
    -webkit-appearance: none !important;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: $input-bg;
    opacity: 0.7;
}
.form-control:focus {
    color: #8898aa;
    background-color: #fff;
    border-color: #06405C;
    outline: 0;
    // box-shadow: $box-shadow-sm;
}
.rdtPicker .rdtTimeToggle:before {

    content: "Time Select";
}

.form-group {

    .form-control-label {
        position: relative;
        left: 0;
        transition: all .3s;
    }

    &:focus-within {
        .form-control-label {
            color: $gray-900; 
            position: relative;
        }
    }
}

.form-control:invalid:focus, .form-control.is-invalid:focus, .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: $gray-900;
}

.form-control {
    background: $white;
    padding-left: $spacer;
    padding-right: $spacer;
    padding-top: $spacer * .75;
    padding-bottom: $spacer * .75;
    transition: all .3s;
    height: auto;

    &:focus {
        color: $gray-900;
        background: $gray-100;
        border: solid 1px $gray-900;
        font-weight: bold;
        border-radius: 0;

        &::placeholder {
            color: lighten($gray-900, 20%);
        }
    }
}

.form-group {
    margin-bottom: $spacer;
}

input[type='checkbox'], input[type='radio'] { filter: grayscale(.8) }

.table {
    .react-datepicker-wrapper {
        display: inline;
    }
}

.react-datepicker-wrapper {
    display: block;

    .react-datepicker__input-container {
        position: relative;
        display: inline-block;
        width: 100%;

        input {
            width: 100%;
            background: $body-bg;
            padding: $input-btn-padding-y $input-btn-padding-x;
            border-radius: $border-radius;
            color: $input-color;

            border: solid 1px $input-border-color;
            border-width: $input-border-width;
            box-shadow: $input-box-shadow;
            &:active {
                background: $input-bg;
            }
        }

    }

}

.date-picker-wrapper {

    &.invalid {
        input {
            border: solid 1px $red;
        }
    }

    &.valid {
        input {
            border: solid 1px $green;
        }
    }
}

.statements-date-picker {
    input {
        text-align: center;
        max-width: 150px;
        border-radius: 0 !important;
        cursor: pointer;
    }
}

label {
    margin-bottom: $spacer / 2.5;
}

.label-block {
    .form-control-label {
        display: block;
    }
}


@media(max-width: 576px) {

    select, input, textarea {
        font-size: 16px !important;
    }

    .form-control  {

        padding: .2rem .7rem !important;
        height: calc(2rem + 2px) !important;

        select, input, textarea {
            font-size: 16px !important;
        }
    }

}

.custom-control-label::before {
    border: 1px solid $blue;
}

.archk-file-upload-item-wrapper {
    // box-shadow: $box-shadow-sm;
    background-color: $white;
}

.dropzone {
    // box-shadow: $box-shadow-sm;
    // border-radius: 4px;
}

.react-select {
    font-size: $font-size-sm;
    color: $body-color;
}

.form-control-invalid {
    display: none;
}
input.is-invalid + .form-control-invalid, select.is-invalid + .form-control-invalid {
    display: inline-block;
    font-size: $font-size-sm;
    color: $warning;
    margin-bottom: 0;
}

.hide-toolbar {
    .ql-toolbar {
        display: none;
    }
}

.ql-large {
    .ql-editor {
        min-height: 300px;
    }
}

.quill {

    .ql-snow .ql-tooltip[data-mode=link]::before {
        display: none;
    }

    .ql-tooltip:after {
        border-bottom: 0.5rem solid $gray-500;
    }

    .ql-picker-label {
        padding-left: 0;
    }
    .ql-container {

        
        .ql-editor {
            background: $white;
            padding-left: $spacer;
            padding-right: $spacer;
            padding-top: $spacer * .75;
            padding-bottom: $spacer * .75;
            transition: all .3s;
            height: auto;
            color: red;

            p {
                color: $input-color !important;
                margin-bottom: 0;
            }
        
            &:focus {
                color: $gray-900;
                background: $gray-100;
                border: solid 1px $gray-900 !important;
                font-weight: bold;
                border-radius: 0;
        
                &::placeholder {
                    color: lighten($gray-900, 20%);
                }
            }
        }
    }
   
}



.archk-master-search {

    .results-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 900;

    }

    .search-icon {
        z-index: 2000;
        color: $body-color !important;
    }

    .search-wrapper {
        input {
            position: relative;
            z-index: 1200;
        }
    }

    .results {
        position: fixed;
        top: 40px;
        width: 500px;
        left: 0;
        right: 0;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        border-radius: $border-radius-sm;
        z-index: 1000;
        background: white;

        max-height: 450px;
        overflow: auto;

        i {
            width: 20px;
        }

      
        h4, li {
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
            padding-top: $spacer * 1;
            padding-bottom: $spacer * 1;
    
        }

        ul {
            // padding-top: $spacer * 2;
            // padding-bottom: $spacer * 2;
            display: block;
            width: 100%;
            border-radius: $border-radius-sm;
            margin-bottom: $spacer;
        }

        li {

            cursor: pointer;
            border-radius: $border-radius;

            &:hover {
                background-color: $info;
                color: $white;
                .text-muted {
                    color: $white !important;
                }
                a {
                    color: $white;
                }
            }

            &.hide-hover {
                cursor: default;
                &:hover {
                    background-color: transparent;
                    color: inherit;
                }
            }

            .badge {
                margin-top: 2px;
                margin-bottom: 2px;
            }

            .nav-section {
                display: inline-block;
                width: 105px;
            }
        }

    }

}

.react-tel-input {

    .flag-dropdown {
        border-radius: 0;
        border: solid 1px $gray-300;
    }

    .form-control {
        width: 100%;
        border-radius: 0;
        font-size: $font-size-sm;
        height: auto;
        padding-top: .375rem;
        padding-bottom: .375rem;
        line-height: 1.5;
        border: solid 1px $gray-300;

        &:active {
            color: #212529;
            background: #f6f9fc;
            border: solid 1px #212529;
            font-weight: bold;
            border-radius: 0;
            box-shadow: inherit;
        }

      
        // line-height: inherit;
        // letter-spacing: inherit;
        // background: inherit;
        // border: inherit;
        // font-size: inherit;
    }
}

.react-datepicker-wrapper .react-datepicker__input-container input {

        width: 100%;
        border-radius: 0;
        font-size: $font-size-sm;
        height: auto;
        padding-top: .375rem;
        padding-bottom: .375rem;
        line-height: 1.5;
        border: solid 1px $gray-300;

        &:active {
            color: #212529;
            background: #f6f9fc;
            border: solid 1px #212529;
            font-weight: bold;
            border-radius: 0;
            box-shadow: inherit;
        }

      
        // line-height: inherit;
        // letter-spacing: inherit;
        // background: inherit;
        // border: inherit;
        // font-size: inherit;

}

.archk-address-lookup {
    .archk-address-lookup-results {
        position: absolute;
        background: $white;
        top: 52px;
        left: 0;
        right: 0;
        z-index: 100;
        width: 100%;

        .archk-address-lookup-results-header {
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
            padding-top: $spacer * .5 ;
            padding-bottom: $spacer * .5 ;
            border-bottom: solid 1px $gray-300;
        }
        .archk-address-lookup-results-body {
            // padding-left: $spacer * 2;
            // padding-right: $spacer * 2;
            // padding-top: $spacer ;
            // padding-bottom: $spacer ;
        }

        .archk-address-lookup-result {
            padding-top: $spacer * .5;
            padding-bottom: $spacer * .5;
            border-bottom: solid 1px $gray-100;

            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
            cursor: pointer;

            &:hover {
                background-color: $gray-200;
            }
        }
    }
}