.archk-messenger {

    margin-left: -18px;
    margin-right: -18px;
    margin-top: -15px;

 

    .archk-messenger-sidebar {

        padding-right: 0;
        height: calc(100vh - 65px);
        background: $white;
        // border-right: solid 1px #eee;
        overflow: auto;

        width: 275px;
        z-index: 2;
    

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                list-style: none;

                border-bottom: solid 1px #eee;
                padding: 8px 16px;

                &:hover {
                    background-color: $gray-200;
                }

                .badge {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }

                &:first-child {
                    &:hover {
                        background-color: $white;
                        cursor: default;
                    }
                }

            }

            .archk-messenger-sidebar-name {
                font-size: 14px;
                font-weight: bold;
            }

            .archk-messenger-sidebar-preview {
                font-size: 12px;
            }

        }

    }

    .archk-messenger-main {
        padding-left: 0;
    }

    .card {
        box-shadow: none !important;
        border-radius: 0 !important;
    }

}

.archk-text-feed-loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    height: 100%;
    width: 100%;
    z-index: 2;


    .archk-text-feed-loader-circle {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
    }

}